
.body{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: rgb(24, 28, 87);
    box-sizing: border-box;
}

.modal{
    height: 80vh;
    width: auto;
    max-width: 90%;
    min-width: 80%;
    background-color: aliceblue;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    
}

.header{
    border: 1px  solid black;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: .5rem 1rem;
    align-items: center;
}

.title{
    font-size: larger;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px;
    display: flex;
    align-items: center;
}

.close-button{
    border: none;
    background: none;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
}
.content{
    overflow: auto;
    padding: 1rem;
    font-weight: 300;
    border: 1px solid black;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
    max-width: 500px;
    min-width: 70%;
    margin: 10px auto;
}
.footer{
    display: flex;
    justify-content: flex-end;
    padding: .5rem 1rem;
}
.btn{
    border: none;
    background: none;
    color: white;
    border-radius: .2rem;
    cursor: pointer;
}
.ok-button{
    background-color: rgb(23, 16, 90);
    padding: .5rem .8rem;
    margin-right: 1rem;
    border-radius: 10px;
    text-decoration: none;
    
}
a{
    text-decoration: none;
    color: white;
}
.cancel-button{
    background-color: orange;
}

.rounded{
    border-radius: 50%;
    padding: .5rem;
    margin-right: 1rem;
    cursor: pointer;
}
.profile-pic{
    width: 100px;
    height: 100px;
    border-radius: 20%;
    margin-right: .5rem;
    margin-left: .5rem;
    float: left;
}
.card{
    align-items: center;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    box-shadow: 0 0 50px rgba(58, 8, 175, 0.2);
    
    
}
.card-body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    
}
.card-title{

    font-size: 1rem;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0px;
}
h5{
    margin: 0;
    padding: 0;
}
p{
    margin: 3px;
    padding: 0;
    font-size: 1.2rem;
}
.btn-center{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    align-items: center;
}
.text-center-p{
    text-align: center;
    margin-top: 1rem;
    font-size: medium;
    margin-left: auto;
}
.logo-img{
    width: 100px;
    height: 100px;
    border-radius: 20%;
    margin-right: 1.5rem;
    margin-left: .5rem;
    float: right;
}
.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #ffffff;
    box-sizing: border-box;
}
.date-div{
    text-align: center;
    margin-top: .5rem;
    font-size: medium;
    font-weight: bold;
    margin-bottom: .5rem;

}
.wish-div{
    text-align: center;
    font-size: smaller;
    font-weight: 500;
    margin-bottom: .5rem;
    background-color: #f1dac4;
    color: black;
    border: 2px solid white;
    border-top: none;
    border-bottom: none;
    border-radius: 1rem;
    padding: 1rem;
}

.minheight{
    min-height: 20px;
}